import React from 'react'

import logo from '../images/logo.svg'

function Header() {
    return (
        <header>
            <img src={logo}/> My Travel Journal.
        </header>
    )
}

export default Header